<template>
  <button
    class="ButtonBase"
    :class="[
      theme,
      { isLoading },
    ]"
    :type="type"
    :disabled="isDisabled || isLoading"
    @click="handleClick">
    <span
      class="__text">
      <slot/>
    </span>

    <LoaderBase
      class="__loader"
      v-if="isLoading"
      :size="24"
      :is-checkmark="false"
      key="button-base-loader"
    />
  </button>
</template>


<script>
import LoaderBase from '@/components/LoaderBase.vue';

export default {
  name: 'ButtonBase',

  components: {
    LoaderBase,
  },

  props: {
    type: {
      type: String,
      default: 'button',
    },
    theme: {
      type: String,
      default: 'primary',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'click',
  ],

  methods: {
    handleClick(e) {
      if (this.isDisabled) return;

      this.$emit('click', e);
    },
  },
};
</script>


<style lang="scss" scoped>
@import '@/styles/_variables';

.ButtonBase {
  position: relative;
  height: 44px;
  padding: 0 16px;
  color: $white;
  font-size: 14px;
  font-weight: $font-weight-bold;
  background: $orange;
  border-radius: $radius;
}

.__loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.isLoading {
  .__text {
    opacity: 0;
  }
}

.ButtonBase.secondary {
  color: #555555;
  background: transparent;
  border: 2px solid #888888;
}
</style>
