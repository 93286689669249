<template>
  <div class="MaybeStudentForm">
    <div class="h3">
      Are you a student?
    </div>

    <p class="mt-3">
      Domo for Students program provides students with a longer trial period and access to
      hundreds of training courses and materials.
    </p>

    <div class="mt-4">
      <ButtonBase
        class="block width-100"
        @click="$emit('yes')">
        Yes, I am an actively enrolled student
      </ButtonBase>

      <ButtonBase
        class="block width-100 mt-2"
        theme="secondary"
        @click="$emit('no')">
        No, I am not a student
      </ButtonBase>
    </div>
  </div>
</template>


<script>
import ButtonBase from '@/components/ButtonBase.vue';

export default {
  name: 'MaybeStudentForm',

  components: {
    ButtonBase,
  },

  emits: [
    'no',
    'yes',
  ],
};
</script>


<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.MaybeStudentForm {
  background-color: $white;
  padding: 40px 16px;
  text-align: center;
}
@include bp-iPhone {
  .MaybeStudentForm {
    padding: 40px;
  }
}
</style>
